@import './hmScrollbar.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    /* TODO */
  }

  :root[data-theme='dark'] {
    --color-black: 0 0% 6%;
    --color-white: 0 0% 98%;

    --color-dark-green-950: 189 74% 10%;
    --color-dark-green-900: 189 73% 13%;
    --color-beige-50: 53 100% 97%;
    --color-beige-100: 53 40% 92%;

    --color-error-500: 0 70% 54%;
    --color-error-600: 0 71% 55%;
    --color-error-600-message: 359 43% 40%;

    --color-alert-400: 43 100% 61%;
    --color-alert-500: 43 100% 49%;
    --color-alert-500-message: 53 33% 34%;

    --color-success-400: 73 60% 60%;
    --color-success-500: 73 61% 55%;
    --color-success-500-message: 89 29% 36%;

    --color-notification-400: 184 71% 71%;
    --color-notification-500: 284 72% 67%;
    --color-notification-500-message: 265 24% 41%;

    --color-sky-400: 198 93% 60%;
    --color-sky-300: 199 95% 74%;
    --color-sky-200: 201 94% 86%;
    --color-pink-400: 313 86% 70%;
    --color-pink-300: 317 87% 82%;
    --color-pink-200: 313 85% 90%;
    --color-orange-400: 25 96% 61%;
    --color-orange-300: 25 97% 72%;
    --color-orange-200: 25 98% 83%;
    --color-teal-400: 172 66% 50%;
    --color-teal-300: 171 77% 64%;
    --color-teal-200: 168 84% 78%;

    --brand-card: 300px;
    --login-modal-lg: 600px;
    --login-modal-md: 544px;
    --login-modal-sm: 464px;
    --header: 96px;
  }

  a:focus {
    outline: none;
  }

  *:focus-visible {
    outline: none;
  }
}
