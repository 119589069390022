/* Regular */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../assets/fonts/Manrope-Regular.ttf') format('truetype');
}

/* Semi-bold */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('../assets/fonts/Manrope-SemiBold.ttf') format('truetype');
}

/* Bold */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../assets/fonts/Manrope-Bold.ttf') format('truetype');
}
